import S from "../../components/Slider.module.css";

import { Suspense, type ReactNode } from "react";
import { Label, Slider, SliderThumb, SliderTrack, Text } from "react-aria-components";
import { isDeepEqual } from "remeda";
import invariant from "tiny-invariant";
import { Loading } from "../../components/loading/Loading.tsx";
import { showWarn } from "../../dev/errorStream.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getVmCreatePrice, type Price, type ResourcePrices, type VmPriceFields } from "../pricing/resourcePricing.ts";
import { SizeBanner } from "./SizeBanner.tsx";
import type { VmSizePackage } from "./VmSize.types.ts";
import { extractOsFields } from "./os/os.ts";
import { usePackages } from "./vmPackages.ts";
import {
    convertToSizeValue,
    filterOsPackages,
    getIsSizeRestricted,
    getSizeComponentRanges,
    matchesPackageOsField,
    sizeToVmPriceFields,
    useSizeParams,
    type ComponentRangeValues,
    type SizeValue,
} from "./vmSizeSelectUtils.ts";

export type VmPriceFieldsOmitSize = Omit<VmPriceFields, keyof ReturnType<typeof sizeToVmPriceFields>>;

export interface VmSizeRadioProps {
    priceFields: VmPriceFieldsOmitSize;

    value: SizeValue;

    onChange: (value: SizeValue) => void;
    ranges: ComponentRangeValues;

    packages?: VmSizePackage[];
    diskOnly?: boolean;
    isDiskPrimary?: boolean;
    // When resizing current VM or package
    isResize?: boolean;
    priceCalculator?: (prices: ResourcePrices, vm: VmPriceFields) => Price;
}

export function VmSizeSelectCustom({
    priceFields,
    value,
    ranges,
    onChange,
    packages,
    diskOnly,
    isDiskPrimary = true,
    priceCalculator = getVmCreatePrice,
    isResize = false,
}: VmSizeRadioProps) {
    invariant(diskOnly == null || isDiskPrimary != null, "diskOnly and isDiskPrimary must be set together");

    //#region Hooks
    const priceList = useSuspenseQueryAtom(pricesAtom);

    const allPackages = usePackages();
    const sizeParams = useSizeParams();

    //#endregion

    const os = extractOsFields(priceFields);
    packages ??= filterOsPackages(allPackages, os);

    function getPriceForSize(size: SizeValue) {
        return priceCalculator(priceList, {
            ...priceFields,
            // NB: This always overwrites `status` with "running"
            ...sizeToVmPriceFields(size, isDiskPrimary),
        });
    }

    function changeSelection(item: SizeValue) {
        onChange(item);
    }

    const packagesRanges = value.isCustom && !isResize ? getSizeComponentRanges(sizeParams, os) : ranges;
    const items = packages.map((item: VmSizePackage, index: number) => {
        const sizeValue = convertToSizeValue(item, false);

        // When custom slider is modified, we need to use ranges of current OS for packages, not the ones from the slider.
        const isSizeRestricted = getIsSizeRestricted(item, packagesRanges ?? ranges, diskOnly);

        const isDisabled = isSizeRestricted || matchesPackageOsField(item, os, "disabled");
        const isSelected = isDeepEqual(sizeValue, value) && !isDisabled;

        const notice = isSizeRestricted ? "Currently not available" : item.notice;

        return (
            <SizeBanner
                key={index}
                price={getPriceForSize(sizeValue)}
                isSelected={isSelected}
                isDisabled={isDisabled}
                onClick={() => changeSelection(sizeValue)}
                notice={notice}
            >
                {!diskOnly && <div>{item.cpu} CPU</div>}
                {!diskOnly && <div>{item.ram / 1024} GB RAM</div>}
                <div>{item.ssd} GB DISK</div>
            </SizeBanner>
        );
    });

    function onChangeCustomSize(componentValue: Partial<SizeValue>) {
        changeSelection({ ...value, ...componentValue, isCustom: true });
    }

    return (
        <>
            {items}

            <SizeBanner
                key="custom"
                variant="custom"
                price={getPriceForSize(value)}
                isSelected={value.isCustom}
                onClick={() => onChangeCustomSize({ ...value })}
            >
                {!diskOnly && (
                    <CustomSlider
                        label="CPU"
                        items={ranges.cpu}
                        value={value.vcpu}
                        onChange={(v) => {
                            onChangeCustomSize({ vcpu: v });
                        }}
                    />
                )}
                {!diskOnly && (
                    <CustomSlider
                        label="GB RAM"
                        items={ranges.ram}
                        renderThumb={(value) => value / 1024}
                        value={value.ram}
                        onChange={(v) => {
                            onChangeCustomSize({ ram: v });
                        }}
                    />
                )}

                <CustomSlider
                    label="GB DISK"
                    items={ranges.ssd}
                    value={value.disks}
                    onChange={(v) => {
                        onChangeCustomSize({ disks: v });
                    }}
                />
            </SizeBanner>
        </>
    );
}

interface CustomSliderProps<T> {
    items: T[];
    value: T;
    onChange: (value: T) => void;
    allowInvalidValue?: boolean;

    renderThumb?: (value: T) => ReactNode;
    label: ReactNode;
    children?: ReactNode;
}

function CustomSlider<T>({
    items,
    value,
    onChange,
    renderThumb = (value) => String(value),
    label,
    children,
    allowInvalidValue = false,
}: CustomSliderProps<T>) {
    invariant(items.length > 0, "items must not be empty");

    let valueIndex = items.findIndex((item) => item === value);
    if (valueIndex === -1)
        if (allowInvalidValue) {
            // If no exact match, find the closest larger value (assumes items are sorted)
            valueIndex = items.findIndex((item) => item > value);
            // Default to the first item
            if (valueIndex === -1) {
                valueIndex = 0;
            }
        } else {
            showWarn("CustomSlider: Invalid value: %o; valid values are: %o", value, items);
            return (
                <div className="text-error">
                    <i>{label}</i>: Invalid value: <u>{renderThumb(value)}</u>
                </div>
            );
        }

    const item = items[valueIndex];

    // Automatically generate min-max description if not provided
    children ??= (
        <>
            {renderThumb(items[0])}-{renderThumb(items[items.length - 1])}
        </>
    );

    return (
        <Slider
            className={S.Slider}
            minValue={0}
            maxValue={items.length - 1}
            value={valueIndex}
            onChange={(index) => onChange(items[index])}
        >
            <Label className={S.Label}>{label}</Label>
            <SliderTrack className={S.SliderTrack}>
                <SliderThumb className={S.SliderThumb}>{renderThumb(item)}</SliderThumb>
            </SliderTrack>
            <Text className={S.Description} slot="description">
                {children}
            </Text>
        </Slider>
    );
}

export interface VmSizeRadioPropsLoaderProps extends Omit<VmSizeRadioProps, "sizeParams"> {}

export function VmSizeSelect(props: VmSizeRadioPropsLoaderProps) {
    return (
        <Suspense fallback={<Loading icon="none" />}>
            <VmSizeSelectCustom {...props} />
        </Suspense>
    );
}
