import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import type { VmParameter } from "@warrenio/api-spec/spec.oats.gen";
import { useMemo, useState } from "react";
import { Input, TextArea } from "react-aria-components";
import { useFormContext, type FieldValues, type Path, type RegisterOptions } from "react-hook-form";
import { WButton } from "../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../components/forms/FieldButtonGroup.tsx";
import { WTextField, type WTextFieldProps } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { cn } from "../../utils/classNames.ts";
import type { VmCreateInputs } from "./vmCreateViewModel.tsx";

export function ParamsTextField<TForm extends FieldValues = VmCreateInputs>({
    params,
    registerOptions,
    multilineRows,
    type = "text",
    ...textFieldProps
}: WTextFieldProps & {
    params: VmParameter;
    registerOptions?: RegisterOptions<TForm>;
}) {
    const fieldName = params.parameter as Path<TForm>;

    //#region Hooks
    const [passwordVisible, setPasswordVisible] = useState(false);

    const form = useFormContext<TForm>();

    // NB: Since "native" and RHF validation do not mix, keep all validation fully custom
    const validate = useParamsValidate(params);

    const { ref, props } = useAriaField(fieldName, form, {
        required: params.mandatory ? requiredMessage : undefined,
        validate,
        ...registerOptions,
    });

    return (
        <WTextField
            // pattern={params.expression}
            description={params.description}
            type={type}
            {...props}
            {...textFieldProps}
        >
            {multilineRows !== undefined ? (
                <TextArea rows={multilineRows} ref={ref} className={cn(FF.FormFieldTextArea, TF.TextArea)} />
            ) : type === "password" ? (
                <FieldButtonGroup hasAbsoluteButton>
                    <Input
                        type={passwordVisible ? "text" : "password"}
                        ref={ref}
                        className={cn(FF.FormFieldInput, TF.Input)}
                    />

                    <WButton
                        size="xs"
                        variant="ghost"
                        action={() => setPasswordVisible((v) => !v)}
                        data-testid="ShowHideButton"
                        icon={cn("size-0.875rem", passwordVisible ? "jp-nopreview-icon" : "jp-preview-icon")}
                    />
                </FieldButtonGroup>
            ) : (
                <Input ref={ref} className={cn(FF.FormFieldInput, TF.Input)} />
            )}
        </WTextField>
    );
}

function useParamsValidate({ constraint, expression }: VmParameter) {
    const regExp = useMemo(() => {
        return constraint === "regexp" && expression ? new RegExp(expression, "u") : undefined;
    }, [constraint, expression]);

    return (value: string) => (regExp?.test(value) === false ? "Value does not match required pattern" : undefined);
}
